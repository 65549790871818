<template>
  <layout-admin-dashboard-view
    :show-toolbar="false"
    >
    <!--:back-route="{ name: 'entity-list', params: { entityType: entityType } }"-->
    <template #title>
      <span>
         {{ translateTitleCase("entity.crud.editEntityTitle") }} -
      </span>
      <span>{{ t(`entity.${entityType}.name`) }}</span>

    </template>

    <template #default>
      <crud-edit
        :api-get-item-url="'entity/' + entityType + '/' + entityId"
        :form-path="'entity/' + entityType + '/' + entityId + '/update'"
        :form-action="'entity/' + entityType + '/' + entityId"
        :validation-context="{id:entityId}"
        >
      </crud-edit>
    </template>
  </layout-admin-dashboard-view>
</template>

<script>
import { useStore } from "vuex";
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";
import { computed, reactive } from "vue";
import CrudEdit from "@/client/components/crud/Edit.vue";

export default {
  components: { CrudEdit },
  props: {
    entityType: {
      type: String,
      default: "default",
    },
    entityId: {
      type: String,
      default: "default",
    },
  },

  setup(props) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(props);

    return { asyncOps, asyncOpsReady, asyncStatus };
  },
  data: function () {
    return {
      isReady: false,
    };
  },
  computed: {
    formPath() {
      return "entity/" + this.entityType + "/" + this.entityId + "/" + "update";
    },
    formAction() {
      return "entity/" + this.entityType + "/" + this.entityId;
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss"></style>
